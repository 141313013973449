import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withRouter } from "react-router";
import ReactHtml from 'raw-html-react';
import Blogarchives from '../Component/Blogarchives';
import Blogcatagories from '../Component/Blogcatagories';
import Blogpopular from '../Component/Blogpopular';
import Blogrecent from '../Component/Blogrecent';
import Blogsearch from '../Component/Blogsearch';
class Blogdetails extends React.Component {
    constructor(){
        super();
        this.state = {
            blog:[],date:[],title:[],content:[],image:[]
        }
    }
    componentDidMount(){
        const id = this.props.match.params.slug3;
        //console.log(process.env.REACT_APP_API_URL + 'api/resource/Blog%20Post?filters=[[%22published%22,%22=%22,%221%22],[%22name%22,%22=%22,%22'+ id +'%22]]&fields=[%22title%22,%22meta_image%22,%22route%22,%22published_on%22,%22content_html%22,%22content_type%22,%22content_md%22,%22content%22,%22blog_category%22,%22meta_image%22]')
        axios.get(process.env.REACT_APP_API_URL + 'api/resource/Blog%20Post?filters=[[%22published%22,%22=%22,%221%22],[%22name%22,%22=%22,%22'+ id +'%22]]&fields=[%22title%22,%22meta_image%22,%22route%22,%22published_on%22,%22content_html%22,%22content_type%22,%22content_md%22,%22content%22,%22blog_category%22,%22meta_image%22]', {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({blog:response.data.data[0]['blog_category']})
                this.setState({date:response.data.data[0]['published_on']})
                this.setState({title:response.data.data[0]['title']})
                this.setState({content:response.data.data[0]['content']})
                this.setState({image:response.data.data[0]['meta_image']})

        })
    }
    render() {
      return <div>
    <div className="main-wrapper">
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                            <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li>
                            <Link to={process.env.PUBLIC_URL +"/blog.html"}>Blog</Link>
                        </li>
                        <li className="active">{this.state.title}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="blog-area pt-120 pb-120">
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-9">
                        <div className="blog-details-wrapper">
                            <div className="blog-details-top">
                                <div className="blog-details-img">
                                <img src={'http://api.windowworldonline.com.au/'+this.state.image}/>
                                </div>
                                <div className="blog-details-content">
                                    <div className="blog-meta-2">
                                        <ul>
                                            <li>{this.state.blog}</li>
                                            <li>{this.state.date}</li>
                                        </ul>
                                    </div>
                                    <h1>{this.state.title}</h1>
                                   <ReactHtml html={this.state.content} />
                                </div>
                            </div>
                            <div className="dec-img-wrapper">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="dec-img mb-50">
                                            <img alt="" src="assets/images/blog/blog-details-2.jpg"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="dec-img mb-50">
                                            <img alt="" src="assets/images/blog/blog-details-3.jpg"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                            
                            
                            
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="sidebar-wrapper sidebar-wrapper-mrg-right">
                       <Blogsearch/>
                        <Blogcatagories/>
                       {/*<Blogrecent/>
                         <Blogarchives/>
                        <Blogpopular/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
}
}
export default withRouter(Blogdetails);

  