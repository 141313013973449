import React, {Component} from 'react';
import {Link} from 'react-router-dom';
class Contact extends React.Component {
    render() {
      return <div>
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home </Link>
                        </li>
                        <li className="active">Contact us </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="contact-area pt-115 pb-120">
            <div className="container">
                <div className="contact-info-wrap-3 pb-85">
                    <h3>contact info</h3>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="single-contact-info-3 text-center mb-30">
                                <i className="icon-location-pin "></i>
                                <h4>our address</h4>
                                <p>3 Canterbury Road,  </p>
								<p>West Lara, Victoria 3212, </p>
								<p>Australia </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="single-contact-info-3 extra-contact-info text-center mb-30">
                                <ul>
                                    <li><i className="icon-screen-smartphone"></i> 03 70353823 </li>
                                    <li><i className="icon-envelope "></i>  <Link to="#"> info@windowworldonline.com.au</Link></li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="get-in-touch-wrap">
                    <h3>Get In Touch</h3>
                    <div className="contact-from contact-shadow">
                        <form id="contact-form" action="assets/mail-php/mail.php" method="post">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <input name="name" type="text" placeholder="Name"/>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <input name="email" type="email" placeholder="Email"/>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <input name="subject" type="text" placeholder="Subject"/>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <textarea name="message" placeholder="Your Message"></textarea>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <button className="submit" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                        <p className="form-messege"></p>
                    </div>
                </div>
                <div className="contact-map pt-120">
                    <div id="map"></div>
                </div>
            </div>
        </div>
        
    </div>
    
}
}
export default Contact;


