import React, {Component} from 'react';
import axios from 'axios';
import Rightcatagories from '../Component/Rightcatagories';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

class Samples extends React.Component {
   
    constructor(){
        super();
        this.state = {
            lis : []
        }
    }
    componentDidMount(){
    axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Products?fields=["name","route","product_name","product_image"]&filters=[["enabled","=","1"],["sample","=","1"]]'), {
        headers : {'Authorization' : process.env.REACT_APP_TOKEN,'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({lis:response.data['data']})
    })
    }
    render() {
      return <div>
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li className="active">Samples </li>
                    </ul>
                </div>
            </div>
        </div>
	<div className="shop-area pt-50 pb-50">
            <div className="container">
				<h3>SAMPLES</h3>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-bottom-area">
                            <div className="tab-content jump">
                                <div id="shop-1" className="tab-pane active">
                                    <div className="row">
                                    {this.state.lis.map((itm,k)=>{
                                    return(
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="single-product-wrap mb-35">
                                                <div className="product-img product-img-zoom mb-15">
                                                <Link to={`/samples/${ itm.route }/${ itm.name }`} >
                                                        <img src={process.env.REACT_APP_API_URL + itm.product_image} alt=""/>
                                                </Link> 
                                                </div>
                                                <div className="product-content-wrap-2 text-center">
                                                    <h3><Link to={`/samples/${ itm.route }/${ itm.name }`} >{ itm.product_name }</Link></h3>
                                                    <div className="product-price-2">
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="product-content-wrap-2 product-content-position text-center">
                                                    <h3><Link to={`/samples/${ itm.route }/${ itm.name }`} >{ itm.product_name }</Link></h3>
                                                    <div className="product-price-2">
                                                        <span></span>
                                                    </div>
                                                    <div className="pro-add-to-cart">
                                                        <button title="Shop Now">SHOP NOW</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                         })} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
}
export default Samples;