import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class Cartsidebar extends React.Component {
    constructor(props){
       
        super(props);

    }

    render() {
        const imgstyle = {
            width: "100%",
            height: "auto",
          
          };
      return <div>
  <div className="sidebar-cart-active">
      <div className="sidebar-cart-all">
      <Link to="#" className="cart-close"><i className="icon_close"></i></Link>
          <div className="cart-content">
              <h3>Shopping Cart</h3>
              <ul>
              {this.props.cartItems.map((itm,j)=>{
                 return(
                  <li key={j} className="single-product-cart">
                      <div className="cart-img">
                      <img src={process.env.REACT_APP_IMAGE+itm.image+'?func=crop&width=98&height=112'} rel="preload" alt="{itm.item_name}"/>
                      </div>
                      <div className="cart-title">
                          <h4>{itm.item_name}</h4>
                          <span> 1 × ${itm.rate}	</span>
                      </div>
                      <div className="cart-delete">
                          <Link to="#">×</Link>
                      </div>
                  </li> )
                })}
                  
              </ul>
              <div className="cart-total">
                  <h4>Total: <span>$ {this.props.cartTotal}</span></h4>
              </div>
              <div className="cart-checkout-btn">
              <Link to={process.env.PUBLIC_URL +"/cart"} className="btn-hover cart-btn-style" >view cart</Link>
              <Link to={process.env.PUBLIC_URL +"/checkout"} className="no-mrg btn-hover cart-btn-style">checkout</Link>
              </div>
          </div>
      </div>
  </div>
  
{/*    <!-- mobile header start -->  */}
<div className="mobile-header-active mobile-header-wrapper-style">
      <div className="clickalbe-sidebar-wrap">
          <a className="sidebar-close"><i className="icon_close"></i></a>
          <div className="mobile-header-content-area">
              <div className="header-offer-wrap-2 mrg-none mobile-header-padding-border-4">
                  <p><span>FREE SHIPPING</span> world wide for all orders over $199</p>
              </div>
              <div className="mobile-search mobile-header-padding-border-1">
                  
              </div>
              <div className="mobile-menu-wrap mobile-header-padding-border-2">
                  {/* <!-- mobile menu start --> */}
                  <nav>
                      <ul className="mobile-menu">
                        <li  className="menu-item-has-children"><Link to={process.env.PUBLIC_URL +"/index"}>ROLLER BLINDS </Link> 
                                <ul className="dropdown">
                                    <li><Link to={process.env.PUBLIC_URL +"/roller-blinds"}>Roller Blinds </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/day-night-roller-blinds"}>Day & Night Roller Blinds</Link></li>
                                </ul>
                            </li> 
                            <li  className="menu-item-has-children"><Link to={process.env.PUBLIC_URL +"/index"}>OTHER BLINDS</Link>
                                <ul className="dropdown">
                                    <li><Link to={process.env.PUBLIC_URL + "/venetian-blinds"}>Venetian Blinds</Link> </li>
                                    <li><Link to={process.env.PUBLIC_URL + "/roman-blinds"}>Roman Blinds</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/vertical-blinds"}>Vertical Blinds </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/panel-glide-blinds"}>Panel Glide Blinds</Link></li>
                                </ul>
                            </li>
                    
                            <li><Link to={process.env.PUBLIC_URL + "/plantation-shutters"}>SHUTTERS </Link>
                                
                            </li>
                            <li><a>CURTAINS </a>
                                
                            </li>
                            <li><a>ROLLER SHUTTERS </a>
                                
                            </li>
                            <li  className="menu-item-has-children"><a>MEASURE & INSTALL </a>
                            <ul className="dropdown">
                                    <li><Link to={process.env.PUBLIC_URL + "/how-to-measure"}>How to Measure </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/how-to-install"}>How to Install</Link></li>
                                    
                            </ul>
                                
                            </li>
                            <li  className="menu-item-has-children"><Link to={process.env.PUBLIC_URL + "/samples"}>SAMPLES</Link>
                            <ul className="dropdown">
                                    <li><Link to={process.env.PUBLIC_URL +"/samples/roller-blinds-order-free-samples"}>Roller Blinds </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/samples/day-night-roller-blinds"}>Day & Night Roller Blinds</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/samples/venetian-blinds"}>Venetian Blinds</Link> </li>
                                    <li><Link to={process.env.PUBLIC_URL + "/samples/roman-blinds"}>Roman Blinds</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/samples/vertical-blinds"}>Vertical Blinds </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/samples/panel-glide-blinds"}>Panel Glide Blinds</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/samples/plantation-shutters"}>plantation Shutters </Link></li>
                                </ul>
                            </li>
                
                            <li><Link to={process.env.PUBLIC_URL + "/blog"}>BLOG</Link></li>
                            
                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>CONTACT </Link></li>
                        </ul>
                  </nav>
                  {/* <!-- mobile menu end --> */}
              </div>
              
             
          </div>
      </div>
  </div>
      </div>
      }
    }
    export default Cartsidebar;