
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Blogarchives from '../Component/Blogarchives';
import Blogcatagories from '../Component/Blogcatagories';
import Blogpopular from '../Component/Blogpopular';
import Blogrecent from '../Component/Blogrecent';
import Blogsearch from '../Component/Blogsearch';
class Blog extends React.Component {
    constructor(){
        super();
        this.state = {
            blo : []
        }
    }
    componentDidMount(){
        console.log(process.env.REACT_APP_API_URL + 'api/resource/Blog%20Post?filters=[[%22published%22,%22=%22,%221%22]]&fields=[%22title%22,%22meta_image%22,%22route%22,%22published_on%22,%22blog_category%22]')
        axios.get(process.env.REACT_APP_API_URL + 'api/resource/Blog%20Post?filters=[[%22published%22,%22=%22,%221%22]]&fields=[%22title%22,%22meta_image%22,%22route%22,%22published_on%22,%22blog_category%22]', {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({blo:response.data['data']})
            console.log(response.data['data'])
        })
    }
    render() {
      return <div>
<div className="main-wrapper">


    <div className="breadcrumb-area bg-gray">
        <div className="container">
            <div className="breadcrumb-content text-center">
                <ul>
                    <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                    </li>
                    <li className="active">Blog</li>
                </ul>
            </div>
        </div>
    </div>
    <div className="blog-area pt-120 pb-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">

                    {this.state.blo.map((itm,k)=>{
            return(

                        <div className="col-lg-6 col-md-6 col-12 col-sm-6">
                            <div className="blog-wrap mb-40">
                                <div className="blog-img mb-20">
                                <Link to={process.env.PUBLIC_URL +'/blog-details/'+itm.route}> <img src={'http://api.windowworldonline.com.au/'+itm.meta_image}/></Link>
                                </div>
                                <div className="blog-content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li key="link" ><Link to="#">{itm.blog_category}</Link></li>
                                            <li key="date">{itm.published_on}</li>
                                        </ul>
                                    </div>
                                    <h1><Link to={process.env.PUBLIC_URL +'/blog-details/'+itm.route}>{itm.title}</Link></h1>
                                </div>
                            </div>
                        </div>)
             })} 

                    </div>
                    <div className="pro-pagination-style text-center mt-10">
                        <ul>
                            <li><Link to="#" activeclassName="prev"><i className="icon-arrow-left"></i></Link></li>
                            <li><Link to="#" activeclassName="active">1</Link></li>
                            <li><Link to="#">2</Link></li>
                            <li><Link to="#" activeclassName="next"><i className="icon-arrow-right"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="sidebar-wrapper sidebar-wrapper-mrg-left">
                   {/* <Blogsearch/>
                    <Blogcatagories/>
                    <Blogrecent/>
                    <Blogarchives/>
                    <Blogpopular/> */}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
   

    
    
    {/*   <!-- Modal end --> */}
</div>

</div>
}
}
export default Blog;


