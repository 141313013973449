import React, {Component} from 'react';
import { Link } from 'react-router-dom';
class Blogarchives extends React.Component {
    render() {
      return <div>
<div className="sidebar-widget shop-sidebar-border mb-40 pt-40">
                            <h4 className="sidebar-widget-title">Archives </h4>
                            <div className="archives-wrap">
                                <select>
                                    <option>Select Month</option>
                                    <option> January 2020 </option>
                                    <option> December 2018 </option>
                                    <option> November 2018 </option>
                                </select>
                            </div>
                        </div>
      </div>
      }
    }
    export default Blogarchives;