import React, {Component} from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

class Howtoinstall extends React.Component {
    constructor(){
        super();
        this.state = {
            ins: [],title:[],
            metaTitle:[],
            metaDesc:[],
            metaOthers:[]
        }
    }
    componentDidMount(){
        const id = this.props.match.params.slug;
        axios.get(process.env.REACT_APP_API_URL + 'api/resource/Web%20Page/'+id, {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
         })
        .then(response=>{
            this.setState({ins:response.data.data['main_section_html']})
            this.setState({title:response.data.data['title']})
        })
        // Dynamic Meta Tags
              axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Route Meta/roller-blinds'), {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                let othrMeta= []
                response.data['data']['meta_tags'].forEach(async (item,index)=>{
                        if(item['key']==='title'){
                            this.setState({metaTitle:item['value']})
                        }
                        else if(item['key']==='description'){
                            this.setState({metaDesc:item['value']})
                        }
                        else{
                            othrMeta.push(<meta property={item['key']} content={item['value']} /> )
                        }                    
                })
                this.setState({metaOthers:othrMeta})
            })
    
            //Dynamic Meta
    }
    render() {
      return <div >
          <Helmet>
                <title>{this.state.metaTitle}</title>
                <meta 
                    name="description" 
                    content={this.state.metaDesc}
                />
                {this.state.metaOthers}
        </Helmet>
           <div className="breadcrumb-area bg-gray">
                <div className="container">
                    <div className="breadcrumb-content text-center">
                        <ul>
                            <li>
                            <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                            </li>
                            <li>
                            <Link to={process.env.PUBLIC_URL +"/how-to-install.html"}>How To Install</Link>
                            </li>
                            <li className="active">{this.state.title}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container">
                <ReactHtml html={this.state.ins} />
            </div>
        </div>
    }
}
export default withRouter(Howtoinstall);
