import React, {Component, useState } from 'react';


import axios from 'axios';
import Homeslider from '../Component/Homeslider';
import Catagories from '../Component/Catagories';
//import Bestseller from '../Component/Bestseller';
//import Recommended from '../Component/Recommended';
import HomeSection from '../Component/HomeSection';
import AuthService from "../services/auth.service";

import {Link} from 'react-router-dom';




class Home extends React.Component {
        constructor(props) {
            super(props);
            this.logOut = this.logOut.bind(this);

            this.state = {
                currentUser: undefined,
                ip:undefined,
                details: '',
                setDetails: ''
            };

        }
        async componentDidMount() {
              //AuthService.clearStorage()
              AuthService.showStorage()
              const user = AuthService.getCurrentUser();
              if(user)
                  console.log(user)

              if(!localStorage.getItem('userIP')){
                  AuthService.getIp();
                 // console.log("user IP New:" + localStorage.getItem('userIP'));
              }
            //  console.log("user IPx:" + localStorage.getItem('userIP'));

        }
        logOut() {
              AuthService.logout();
        }

     
        render() {
          
        return <div>
            
              <Homeslider/>
              <HomeSection section="Service Area"/>
              <Catagories/>
                {/*} <Bestseller/>
              <Recommended/> */}
              <HomeSection section="Home About"/>
              </div>
        }
}
export default Home;