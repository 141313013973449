import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';

class Homeslider extends React.Component {
    constructor(){
        super();
        this.state = {
            slider : []
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/windowworld.api.main_slider', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({slider:response.data['message']})
               // console.log("Slider")
              //  console.log(response.data)
            })
        
    }
    render() {

        var settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            loop: true,
            dots: false,
            arrows: false,
          
        }
        const sliderstyle = {
            width: "100%",
            height: "auto",
          
          };
      return <div style={{minHeight:'350px'}}>

<div className="slider-area bg-gray-2">
      <div className="hero-slider-active-2 nav-style-1 nav-style-1-green">
      <Slider {...settings}>
            {this.state.slider.map((itm,p)=>{
            return(
                    
                    <div key={p} className="single-hero-slider single-hero-slider-hm3 single-animation-wrap" style={{ minHeight:'350px'}}>
                        
                        <img src={process.env.REACT_APP_IMAGE+itm.image+'?func=crop&width=1200&height=350'} rel="preload" width="100" height="1"  style={sliderstyle}/>
                        
                    </div>)
            })}
            </Slider>
</div>
  </div>
  </div>
}}

export default Homeslider;