

import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactHtml from 'raw-html-react';


class Cart extends React.Component {
    constructor(props){
       
        super(props);
        this.state = {
            cartItems: [],
            cartTotal: [],
            cartGrandTotal: [],
        }
        
        
        
    }
    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.get_cart_quote'),{
                headers : {'Authorization' : USR_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cartItems:response.data['message']['items']})
                this.setState({cartTotal:response.data['message']['net_total']})
                this.setState({cartGrandTotal:response.data['message']['grand_total']})
                console.log(response.data['message']['items'])
            })
        }
    }
    clearCart = async e => {
        e.preventDefault();

    }
    applyCoupon = async e => {
            e.preventDefault();

    }

    render() {
      return <div>


    <div className="main-wrapper">
                     
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                            <Link to={process.env.PUBLIC_URL + "/index.html"}>Home</Link>
                        </li>
                        <li className="active">Cart Page </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="cart-main-area pt-115 pb-120">
            <div className="container">
                <h3 className="cart-page-title">Your cart items</h3>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <form action="#">
                            <div className="table-content table-responsive cart-table-content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th style={{textAlign:'left'}}>Product Name</th>
                                            <th></th>
                                             <th>Subtotal</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.cartItems.map((itm,j)=>{
                                        return(
                                        <tr>
                                            <td className="product-thumbnail">
                                            <img src={process.env.REACT_APP_IMAGE+itm.image+'?func=crop&width=100&height=100'} alt=""/>
                                            </td>
                                            <td style={{textAlign:'left'}} className="product-name"><strong>{itm.item_name}</strong><br /><ReactHtml html={itm.description}  /></td>
                                            <td className="product-name"></td>
                                            <td className="product-subtotal">${itm.rate}</td>
                                            <td className="product-remove">
                                            <Link to="#">x</Link>
                                            </td>
                                        </tr>)
                                         })}
                                      
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="cart-shiping-update-wrapper">
                                        <div className="cart-shiping-update">
                                        <Link to="/">Continue Shopping</Link>
                                        </div>
                                        <div className="cart-clear">
                                            <Link to="#" onClick={this.clearCart}>Clear Cart</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="discount-code-wrapper">
                                    <div className="title-wrap">
                                        <h4 className="cart-bottom-title section-bg-gray">Use Coupon Code</h4>
                                    </div>
                                    <div className="discount-code">
                                        <p>Enter your coupon code if you have one.</p>
                                        <form>
                                            <input type="text" required="" name="name"/>
                                            <button className="cart-btn-2" type="button" onClick={this.applyCoupon}>Apply Coupon</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="grand-totall">
                                    <div className="title-wrap">
                                        <h4 className="cart-bottom-title section-bg-gary-cart">Cart Total</h4>
                                    </div>
                                    <h5>NET Total  <span>{this.state.cartTotal}</span></h5>
                                    <div className="total-shipping">
                                        <h5>Total shipping</h5>
                                        <ul>
                                            <li> Standard <span>$30.00</span></li>
                                           
                                        </ul>
                                    </div>
                                    <h4 className="grand-totall-title">Grand Total <span>{this.state.cartGrandTotal}</span></h4>
                                    <Link to="/checkout">Proceed to Checkout</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
       
    </div>

    </div>
}
}
export default Cart;


