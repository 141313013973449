
import React, {Component} from 'react';
import axios from 'axios';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
class Catagories extends React.Component {
    constructor(){
        super();
        this.state = {
            cat : []
        }
    }
    componentDidMount(){

            axios.get(process.env.REACT_APP_API_URL + 'api/resource/Product%20Group?fields=[%22name%22,%22image%22,%22is_group%22,%22route%22]&filters=[[%22is_group%22,%22=%22,%220%22]]', {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cat:response.data['data']})
               // console.log("Categories")
               //  console.log(process.env.REACT_APP_API_URL + 'api/resource/Product%20Group?fields=[%22name%22,%22image%22,%22is_group%22,%22route%22]&filters=[[%22is_group%22,%22=%22,%220%22]]')
               // console.log(response.data)
            })
    }
    render() {

        var settings = {
            slidesToShow: 8,
            slidesToScroll: 1,
            fade: false,
            loop: true,
            dots: false,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        }
        const sliderstyle = {
            width: "100%",
            height: "auto",
          
          };
            return <div>
                    <div className="product-categories-area pb-50">
                        <div className="container">
                            <div className="section-title-btn-wrap border-bottom-3 mb-50 pb-20">
                                <div className="section-title-3">
                                    <h2>Top Categories</h2>
                                </div>
                                <div className="btn-style-7">
                                    <Link to={process.env.PUBLIC_URL +"/shop.html"}>All Product</Link>
                                </div>
                            </div>
                            <div className="product-categories-slider-1 nav-style-3">
                            <Slider {...settings}>
                                {this.state.cat.map((itm,j)=>{
                                 return(
                                    <div key={j} className="product-plr-1">
                                        <div className="single-product-wrap">
                                            <div className="product-img product-img-border mb-20">
                                                <Link to={itm.route}>
                                                    <img src={process.env.REACT_APP_IMAGE+itm.image+'?w=200'} rel="preload" height="200" width="1" style={sliderstyle} alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-content-categories-2 text-center">
                                                <h5>{itm.name}</h5>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            }
}
export default Catagories;