import React, {Component} from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react'
import { withRouter } from "react-router";

class HomeSection extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            on: []
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Homepage Section/') + this.props.section, {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({on:response.data.data['section_html']})
                
            })
    }


    render() {
      return <div style={{minHeight:'200px'}}>
          <ReactHtml html={this.state.on} />
    </div>
}
}
export default HomeSection;
