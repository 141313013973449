import axios from "axios";

 
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(username, password) {
    const loginData= {
        usr:username,
        pwd:password
    }

    return axios
      .post(API_URL + "api/method/login", loginData)
      .then(response => {
          //console.log(response.data)
        if (response.data) {
          const userData= { 
              full_name:response.data['full_name'],
              uid:loginData.usr
          }
          localStorage.setItem("user", JSON.stringify(userData));
          if(!localStorage.getItem("userType")){

                localStorage.setItem("userType", "Auth");

          }
        }

        return response.data;
      });
  }
  setToken(){

    
  }
  getIp(){
   // console.log(userlData)
  
       return axios.get(process.env.REACT_APP_API_URL + encodeURI('/api/method/windowworld.api.get_ip'), {
              headers : {'content-type': 'application/json'}})
              .then(response=>{  localStorage.setItem("userIP", response.data['message']) })
              .catch(err =>{ console.log(err)})
   
  }

  logout() {
    localStorage.removeItem("user");
    
  }

  async register(username, email, password) {
    return axios.post(API_URL + "/api/method/windowworld.api.user_register", {
      username,
      email,
      password
    });
  }
  switchUser(temUser=''){


  }
  async guestRegister() {
    
    if(!localStorage.getItem('userIP')){
        AuthService.getIp();
    }
    
    let userIP = localStorage.getItem('userIP');
    
    const userData= {
        mobile:userIP,
        full_name:userIP,
        email:userIP + '@demouser.com'
    }
    
              return axios.post(API_URL + "/api/method/windowworld.api.user_register", userData, {
                              headers : {'Authorization' : process.env.REACT_APP_TOKEN, 'content-type': 'application/json'}}
                      )
                .then(response=>{
                    const userData= { 
                          full_name:'Guest User',
                          api_key:response.data['message'].api_key,
                          api_secret:response.data['message'].api_secret
                      }
                        localStorage.setItem("guestuser", JSON.stringify(userData))
                        localStorage.setItem("userType", 'guest')
                        localStorage.setItem("userToken", 'token ' + response.data['message'].api_key + ":" +response.data['message'].api_secret)
                       
                      })
                .catch(err =>{ console.log(err)})
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getGuestUser() {
    return JSON.parse(localStorage.getItem('guestuser'));
  }
  randomString() {
      let length=6, chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
      return result;
  }
  clearStorage(){
      localStorage.removeItem("user");
      localStorage.removeItem("guestuser");
      localStorage.removeItem("userType");
      localStorage.removeItem("userToken");
      localStorage.removeItem('userIP')
  }
  showStorage(){
      console.log("user")
      console.log(localStorage.getItem("user"));
      console.log("guestuser")
      console.log(localStorage.getItem("guestuser"));
      console.log("userType")
      console.log(localStorage.getItem("userType"));
      console.log("userToken")
      console.log(localStorage.getItem("userToken"));
      console.log("userIP")
      console.log(localStorage.getItem('userIP'));
  }
}

export default new AuthService();
