
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import ReactHtml from 'raw-html-react';
import axios from 'axios';
import { withRouter } from "react-router";
import Blindoptions from '../Component/Blindoptions';
import StickyBox from "react-sticky-box";
import {Helmet} from "react-helmet";

import AuthService from "../services/auth.service";
import CartService from '../services/cart.service';

class ProductDetails extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            blind : [],
            opts : [],
            contentDesc : [],
            total: [],
            item_code:undefined,
            buttontext:'Add to Cart'
           

        }
        
    }
    async componentDidMount(){
        const catg = this.props.match.params.catg;
        const route = this.props.match.params.route;
        const id = this.props.match.params.id;
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Products/') + id, {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            console.log(response.data['data'])
            this.setState({blind:response.data['data']})
            this.setState({total:response.data['data']['base_rate']})
            this.setState({opts:response.data['data']['options']})
            this.setState({item_code:response.data['data']['item']})
            if (response.data['data']['website_content'])
                this.setState({contentDesc:response.data['data']['website_content']})
        })

         // Dynamic Meta Tags

        /* axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Route Meta/'+ catg +'/'+ route +'/'+id), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            let othrMeta= []
            response.data['data']['meta_tags'].forEach(async (item,index)=>{
                    if(item['key']==='title'){
                        this.setState({metaTitle:item['value']})
                    }
                    else if(item['key']==='description'){
                        this.setState({metaDesc:item['value']})
                    }
                    else{
                        othrMeta.push(<meta property={item['key']} content={item['value']} /> )
                    }                    
            })
            this.setState({metaOthers:othrMeta})
            //console.log(response.data['data'])
        })
        .catch(function (error) {
            //console.log(error);
          })*/

        //Dynamic Meta


    }
    inputWidth= e => {
        e.preventDefault();
        try {
                document.getElementById("swidth").remove();
                document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='swidth' ><strong>Width</strong> : " + e.target.value + "</div>");
        }
        catch(err) {
            document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='swidth' ><strong>Width</strong> : " + e.target.value + "</div>");                                 
        }
    }
    inputDrop= e => {
        e.preventDefault();
        try {
                document.getElementById("sdrop").remove();
                document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='sdrop' ><strong>Drop</strong> : " + e.target.value + "</div>");
        }
        catch(err) {
            document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='sdrop' ><strong>Drop</strong> : " + e.target.value + "</div>");                                 
        }
        
    }
    addToCart= async e => {
        this.setState({ buttontext:'Updating...'})
        const delay = ms => new Promise(res => setTimeout(res, ms));
        localStorage.removeItem("guestuser");
        e.preventDefault();
        try {
                const user = AuthService.getCurrentUser();
                //console.log(user)
                if(user == null){
                   // console.log('2nd')
                   const guestuser = await AuthService.getGuestUser()
                   

                    if(guestuser == null){
                        await AuthService.guestRegister();
                    }
                }
                let text = document.getElementById("options-selected").innerHTML;
                
                let itemData={
                    item_code:this.state.item_code, 
                    description:text, 
                    qty: 1, 
                    rate:this.state.total,
                }

                
                await CartService.add_item(itemData)
                await CartService.update_cart()
               
                this.setState({ buttontext:'Product Added'})
                await delay(3000);
                this.setState({ buttontext:'Add Next Item'})

                //document.getElementById("sdrop").remove();
                //  document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='sdrop' ><strong>Drop</strong> : " + e.target.value + "</div>");
        }
        catch(err) {
          //  document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='sdrop' ><strong>Drop</strong> : " + e.target.value + "</div>");                                 
        }
    }
    getOptions = async (OptionName) => {
        let res = await axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Product Options/') + OptionName, {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        let itemList=[];
      
        this.setState({ on:res.data['data']})
        this.setState({ values:res.data['data']['values']})

        //console.log(res.data['data']['values'])

        this.state.values.forEach(async (item,index)=>{
          //  console.log(item.name + " :-: " + item.parent)
            itemList.push(<div className="radio-selector"> 
                         <input id={item.name} type="radio" name={item.parent} value={item.option_value_name} />
                         <label className="radio-label" htmlFor={item.name} style={{ backgroundImage:'url(assets/images/RollerBlind_facefit.png)'}}><span>{item.option_value_name}</span></label>            
                     </div>)
        })
        return itemList

    }
     // Function to update state
    handleUpdate = (newtotal) => {
        this.setState({ total: newtotal })
    }
    render() {
     
        var skips = ["Location", "Qty", "Width", "Drop", "Control - Roller Blinds"];

      return <div>
         <Helmet>
                <title>{this.state.metaTitle}</title>
                <meta 
                    name="description" 
                    content={this.state.metaDesc}
                />
                {this.state.metaOthers}
        </Helmet>
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li key="home">
                            <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li key={this.state.blind.product_name} className="active">{this.state.blind.product_name} </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="shop-area pt-50 pb-50">
                <div className="container">
                    <h3>{this.state.blind.product_name}</h3>
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className=" mb-20">
                                <div className="d-flex your-order-area ">
                                        <div className="col-md-4 pr-2">
                                            <label>Width(mm)</label>
                                            <input type="text" id="width" placeholder=""  onChange={this.inputWidth} />
                                        </div>
                                        <div className="col-md-4 pl-2 pr-2">
                                            <label>Drop(mm)</label>
                                            <input type="text" id="drop" placeholder="" onChange={this.inputDrop} />
                                        </div>
                                        <div className="col-md-4 pl-2 pr-2">
                                            <div className="radio-selector">
                                                <input id="control1" type="radio" name="control" value="Left Side Control" />
                                                <label className="radio-label" htmlFor="control1" style={{ backgroundImage:'url('+ process.env.PUBLIC_URL + '/assets/images/Left-side-control.jpg)'}}><span>Left Side Control</span></label>
                                                <input id="control2" type="radio" name="control" value="Right Side Control" />
                                                <label className="radio-label"htmlFor="control2" style={{ backgroundImage:'url('+ process.env.PUBLIC_URL + '/assets/images/Right-side-control.jpg)'}}><span>Right Side Control</span></label>
                                            </div>
                                        </div>
                                        
                                </div>
                                
                                {this.state.opts.map((itm,k)=>{
                                     if(skips.indexOf(itm.group_name) === -1){
                                            var tit = itm.group_name.split("-")
                                            return(
                                                
                                                <div className="mt-4">
                                                        <Blindoptions updateState={this.handleUpdate} OptionName={itm.product_option_id}  />                                                       
                                                </div>
                                                    
                                            )
                                    }   
                                })} 
                               
                            </div>
                            
                        </div>
                        
                            <div className="col-lg-4 col-md-4 ">
                                <StickyBox offsetTop={20} offsetBottom={20}>
                                    <div className="product-details-content pro-details-content-mrg">
                                        <h2 id="product" data-item={this.state.blind.item}>{this.state.blind.product_name}</h2>
                                        <p></p>
                                        <div className="pro-details-price">
                                            <span id="new-price" className="d-block new-price">${this.state.total}</span> 
                                            <p className="d-block ">&nbsp;&nbsp;  (includes GST & our best price guarantee)</p>
                                        </div>
                                        <div class="tax-info">
                                                
                                            </div>
                                                        
                                    
                                        <div className="product-details-meta">
                                            <div id="options-selected">
                                              
                                            </div>
                                        </div>
                                        <div className="pro-details-action-wrap">
                                            <div className="pro-details-add-to-cart">
                                                <a title="Add to Cart" href="#"  onClick={this.addToCart}>{this.state.buttontext} </a>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </StickyBox>    
                            </div>
                           
                    </div>
                    <hr />                        
                    <div className="row mt-5">
                            <div className="col-md-12">
                                <ReactHtml html={this.state.contentDesc}  />
                            </div>

                    </div>
                </div>
            </div>
        
    
    </div>

}
}
export default withRouter(ProductDetails);