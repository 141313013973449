
import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
class Rightcatagories extends React.Component {
       
        render() {
          return <div>
<div className="sidebar-wrapper sidebar-wrapper-mrg-left">

<div className="sidebar-widget shop-sidebar-border mb-35 pt-40">
    <h4 className="sidebar-widget-title">All Categories </h4>
    <div className="shop-catigory">
        <ul>      
            <li> <Link to={process.env.PUBLIC_URL +"/roller-blinds.html"}>ROLLER BLINDS</Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"/day-night-roller-blinds.html"}>DAY & NIGHT ROLLER BLINDS</Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"/roman-blinds.html"}>ROMAN BLINDS </Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"/venetian-blinds.html"}>VENETIAN BLINDS </Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"/vertical-blinds.html"}>VERTICAL BLINDS </Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"/panel-glide-blinds.html"}>PANEL GLIDE BLINDS </Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"/plantation-shutters.html"}>PLANTATION SHUTTERS </Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"#"}>ROLLER SHUTTERS </Link></li>
            <li> <Link to={process.env.PUBLIC_URL +"#"}>CURTAINS </Link></li>
        </ul>
    </div>
</div>




</div>
</div>

}
    }
    export default Rightcatagories;