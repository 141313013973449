
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withRouter } from "react-router";
import Blindoptions from '../Component/Blindoptions';



class SampleDetails extends React.Component {
    
    constructor(){
        super();
        this.state = {
            blind : [],
            opts : []

        }
    }
    async componentDidMount(){
        const route = this.props.match.params.route;
        const id = this.props.match.params.id;
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Products/') + id, {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({blind:response.data['data']})
            this.setState({opts:response.data['data']['options']})
            console.log("Sample Page")
            console.log(response.data['data']['options'])
        })
    }
    getOptions = async (OptionName) => {
        let res = await axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Product Options/') + OptionName, {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        let itemList=[];
      
        this.setState({ on:res.data['data']})
        this.setState({ values:res.data['data']['values']})
        console.log(res.data['data']['values'])

        this.state.values.forEach(async (item,index)=>{
            console.log(item.name + " :-: " + item.parent)
            itemList.push(<div className="radio-selector"> 
                         <input id={item.name} type="radio" name={item.parent} value={item.option_value_name} />
                         <label className="radio-label" for={item.name} style={{ backgroundImage:'url(assets/images/RollerBlind_facefit.png)'}}><span>{item.option_value_name}</span></label>            
                     </div>)
        })
        return itemList

    };
    render() {
     
      return <div>
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Samples</Link>
                        </li>
                        <li className="active">{this.state.blind.product_name} </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="shop-area pt-50 pb-50">
                <div className="container">
                    <h3>{this.state.blind.product_name} - Sample</h3>
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className=" mb-20">
                                                               
                                {this.state.opts.map((itm,k)=>{
                                     if(itm.sample == 1){
                                            return(
                                                <div className="row mt-4">
                                                        <Blindoptions OptionName={itm.product_option_id} />                                                       
                                                </div>
                                            )
                                    }   
                                })} 
                               
                            </div>
                            
                        </div>
                        <div className="col-lg-4 col-md-4 sidebar-active">
                            <div className="product-details-content pro-details-content-mrg">
                                <h2>{this.state.blind.product_name}</h2>
                                <p>Seamlessly predominate enterprise metrics without performance based process improvements.</p>
                                                    
                                <div className="pro-details-action-wrap">
                                    <div className="pro-details-add-to-cart">
                                        <a title="Add to Cart" href="#">Add To Cart </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    
    </div>

}
}
export default withRouter(SampleDetails);