import React, {Component} from 'react';
import { Link } from 'react-router-dom';
class Blogpopular extends React.Component {
    render() {
      return <div>
<div className="sidebar-widget shop-sidebar-border pt-40">
                            <h4 className="sidebar-widget-title">Popular Tags</h4>
                            <div className="tag-wrap sidebar-widget-tag">
                                <Link to="#">Clothing</Link>
                                <Link to="#">Accessories</Link>
                                <Link to="#">For Men</Link>
                                <Link to="#">Women</Link>
                                <Link to="#">Fashion</Link>
                            </div>
                        </div>
      </div>
      }
    }
    export default Blogpopular;