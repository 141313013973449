import React, {Component} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
class Blogrecent extends React.Component {
    constructor(){
        super();
        this.state = {
            rec : []
        }
    }
    componentDidMount(){
        axios.get('https://api.windowworldonline.com.au/api/resource/Blog%20Post?filters=[[%22published%22,%22=%22,%221%22]]&fields=[%22title%22,%22meta_image%22,%22route%22,%22published_on%22,%22blog_category%22]&limit_start=0&limit_page_length=3&order_by=published_on%20desc', {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({rec:response.data['data']})
            console.log(response.data['data'])
        })
    }
    render() {
          return <div>
                        <div className="sidebar-widget shop-sidebar-border mb-40 pt-40">
                            <h4 className="sidebar-widget-title">Recent Posts </h4>
                            <div className="recent-post">
                                {this.state.rec.map((itm,k)=>{
                                return(
                                <div className="single-sidebar-blog">
                                    <div className="sidebar-blog-img">
                                    <Link to={process.env.PUBLIC_URL +'/blog-details/'+itm.route}> <img src={'http://api.windowworldonline.com.au/'+itm.meta_image}/></Link>
                                    </div>
                                    <div className="sidebar-blog-content">
                                        <h5><Link to={process.env.PUBLIC_URL +'/blog-details/'+itm.route}>{itm.title}</Link></h5>
                                        <span>{itm.published_on}</span>
                                    </div>
                                </div>)
                                 })} 
                               
                            </div>
                        </div>
                    </div>
      }
    }
    export default Blogrecent;