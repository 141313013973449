import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Cartsidebar from './Cartsidebar';



class Header extends React.Component {
    constructor(props){
       
        super(props);
        this.state = {
            cartQty: 0,
            cartAmt: 0.00,
            cartItems: [],
            cartTotal: []
        }
        
        
        
    }
    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.get_cart_count'),{
                headers : {'Authorization' : USR_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cartQty:response.data['message'][0]['count']})
                this.setState({cartAmt:response.data['message'][0]['total']})
                console.log(response.data['message'][0])
            })
        }
        
        
    }
    updateCart = async e => {
       // e.preventDefault();
       const USR_TOKEN = localStorage.getItem("userToken")
       if(USR_TOKEN){
           axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/erpnext.shopping_cart.cart.get_cart_quotation'),{
               headers : {'Authorization' : USR_TOKEN, 
               'content-type': 'application/json'}
           })
           .then(response=>{
               this.setState({cartItems:response.data['message']['doc']['items']})
               this.setState({cartTotal:response.data['message']['doc']['total']})
           // console.log(response.data['message']['doc']['items'])
           })
       }
    }
    render() {
        const imgstyle = {
            width: "100%",
            height: "auto",
          };
      return <div>
      <div className="main-wrapper">
      <header className="header-area">
          <div className="header-large-device">
              
              <div className="header-middle header-middle-padding-1">
                  <div className="container">
                      <div className="row align-items-center">
                          <div className="col-xl-3 col-lg-3">
                              <div className="logo">
                                <Link to="/"><img src="assets/images/logo/logo.png" rel="preload" width="1" height="1" style={imgstyle} alt="logo"/></Link>
                              </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                              
                          </div>
                          <div className="col-xl-3 col-lg-3">
                              <div className="header-action header-action-flex">
                                  <div className="same-style-2 same-style-2-font-inc">
                                  <Link to="/login-register"><i className="icon-user"></i></Link>
                                  </div>
                                  
                                  <div className="same-style-2 same-style-2-font-inc header-cart" onClick={this.updateCart}>
                                      <Link to="#" className="cart-active">
                                          <i className="icon-basket-loaded"></i><span id="pro-count" className="pro-count green">{this.state.cartQty}</span>
                                          <span id="cart-amount" className="cart-amount">${this.state.cartAmt}</span>
                                      </Link>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="header-bottom" >
                  <div className="container">
                      <div className="row align-items-center">
                          
                          <div className="col-lg-12">
                              <div className="main-menu main-menu-padding-1 main-menu-font-size-14 main-menu-lh-2">
                                  <nav>
                                      <ul>
                                          <li><Link to="/index">ROLLER BLINDS </Link> 
                                              <ul className="sub-menu-style">
                                                  <li><Link to="/roller-blinds">Roller Blinds </Link></li>
                                                  <li><Link to="/day-night-roller-blinds">Day & Night Roller Blinds</Link></li>
                                              </ul>
                                            </li> 
                                          <li><Link to="/index">OTHER BLINDS</Link>
                                              <ul className="sub-menu-style">
                                                  <li><Link to="/venetian-blinds">Venetian Blinds</Link> </li>
                                                  <li><Link to="/roman-blinds">Roman Blinds</Link></li>
                                                  <li><Link to= "/vertical-blinds">Vertical Blinds </Link></li>
                                                  <li><Link to= "/panel-glide-blinds">Panel Glide Blinds</Link></li>
                                              </ul>
                                            </li>
                                    
                                          <li><Link to= "/plantation-shutters">SHUTTERS </Link>
                                             
                                          </li>
                                          <li><a>CURTAINS </a>
                                              
                                          </li>
                                           <li><a>ROLLER SHUTTERS </a>
                                             
                                          </li>
                                          <li><a>MEASURE & INSTALL </a>
                                          <ul className="sub-menu-style">
                                                  <li><Link to= "/how-to-measure">How to Measure </Link></li>
                                                  <li><Link to= "/how-to-install">How to Install</Link></li>
                                                 
                                            </ul>
                                             
                                          </li>
                                          <li><Link to= "/samples">SAMPLES</Link>
                                          <ul className="sub-menu-style">
                                                  <li><Link to="/samples/roller-blinds-order-free-samples">Roller Blinds </Link></li>
                                                  <li><Link to="/samples/day-night-roller-blinds">Day & Night Roller Blinds</Link></li>
                                                  <li><Link to= "/samples/venetian-blinds">Venetian Blinds</Link> </li>
                                                  <li><Link to= "/samples/roman-blinds">Roman Blinds</Link></li>
                                                  <li><Link to= "/samples/vertical-blinds">Vertical Blinds </Link></li>
                                                  <li><Link to= "/samples/panel-glide-blinds">Panel Glide Blinds</Link></li>
                                                  <li><Link to= "/samples/plantation-shutters">plantation Shutters </Link></li>
                                              </ul>
                                          </li>
                                
                                          <li><Link to= "/blog">BLOG</Link></li>
                                          
                                          <li><Link to= "/contact">CONTACT </Link></li>
                                      </ul>
                                  </nav>
                              </div>
                          </div>
                         
                      </div>
                  </div>
              </div>
          </div>
          <div className="header-small-device small-device-ptb-1">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-5">
                          <div className="mobile-logo">
                          <Link to="/index"></Link>
                                  <img alt="" src="assets/images/logo/logo.png"/>
                            </div>
                      </div>
                      <div className="col-7">
                          <div className="header-action header-action-flex">
                              <div className="same-style-2 same-style-2-font-inc">
                              <Link to="/login-register"><i className="icon-user"> </i></Link>
                              </div>
                             
                              <div className="same-style-2 same-style-2-font-inc header-cart">
                              <Link to="#" className="cart-active">
                                      <i className="icon-basket-loaded"></i><span className="pro-count green">02</span>
                                  </Link>
                              </div>
                              <div className="same-style-2 main-menu-icon">
                              <Link to="#" className="mobile-header-button-active"><i className="icon-menu"></i> </Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
      </header>
    

      </div>
      <Cartsidebar cartItems={this.state.cartItems} cartTotal={this.state.cartTotal}/>
      </div>
      
    }
}
export default Header;

