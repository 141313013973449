import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class CartService {
  async add_item(itemData) {

      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
      
        return axios
          .post(API_URL + "api/method/windowworld.api.update_item", itemData, {
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}}
            )
          .then(response => {
              console.log(response.data)

          });
      
      }
  }
  async update_cart(){
      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.get_cart_count'),{
          headers : {'Authorization' : USR_TOKEN, 
          'content-type': 'application/json'}
      })
      .then(response=>{
          document.getElementById("pro-count").innerText = response.data['message'][0]['count']
          document.getElementById("cart-amount").innerText = response.data['message'][0]['total']
      })

      }
  }
  transferCart(){
      
  }

 
}

export default new CartService();
