import React, {Component} from 'react';
import AuthService from "../services/auth.service";
import { BrowserRouter, Route, Link, withRouter, Redirect } from 'react-router-dom';



class Myaccounts extends React.Component {

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
    
        this.state = {
            currentUser: undefined,
        };
      }
      componentDidMount() {
          
        const user = AuthService.getCurrentUser();
        if (!user) this.setState({ redirect: "/" });
        
        if (user) {
          this.setState({
            currentUser: user.full_name
          });
        }
      }
    
      logOut = e => {
        e.preventDefault();
        AuthService.logout()
        const { history } = this.props;
              this.props.history.push("/");
              window.location.reload();
      }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
      return <div>
            <div className="main-wrapper">
            
                
                <div className="breadcrumb-area bg-gray">
                    <div className="container">
                        <div className="breadcrumb-content text-center">
                            <ul>
                                <li>
                                <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                                </li>
                                <li className="active">my account </li>
                            </ul>
                        </div>
                    </div>
                </div>
            {/*  <!-- my account wrapper start --> */}
                <div className="my-account-wrapper pt-120 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            {/*  <!-- My Account Page Start --> */}
                                <div className="myaccount-page-wrapper">
                                    {/* <!-- My Account Tab Menu Start --> */}
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <div className="myaccount-tab-menu nav" role="tablist">
                                                <a href="#dashboad" className="active" data-toggle="tab"><i className="fa fa-dashboard"></i>
                                                    Dashboard</a>
                                                <a href="#orders" data-toggle="tab"><i className="fa fa-cart-arrow-down"></i> Orders</a>
                                                <a href="#address-edit" data-toggle="tab"><i className="fa fa-map-marker"></i> address</a>
                                                <a href="#account-info" data-toggle="tab"><i className="fa fa-user"></i> Account Details</a>
                                                <a href="#" onClick={this.logOut}><i className="fa fa-sign-out"></i> Logout</a>
                                            </div>
                                        </div>
                                    {/*   <!-- My Account Tab Menu End -->
                                        <!-- My Account Tab Content Start --> */}
                                        <div className="col-lg-9 col-md-8">
                                            <div className="tab-content" id="myaccountContent">
                                            {/*  <!-- Single Tab Content Start --> */}
                                                <div className="tab-pane fade show active" id="dashboad" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3>Dashboard</h3>
                                                        <div className="welcome">
                                                            <p>Hello, <strong>{this.state.currentUser}</strong> (If Not <strong>Tuntuni !</strong><Link to="#" className="logout" onClick={this.logOut}> Logout</Link>)</p>
                                                        </div>

                                                        <p className="mb-0">From your account dashboard. you can easily check & view your recent orders, manage your shipping and billing addresses and edit your password and account details.</p>
                                                    </div>
                                                </div>
                                            {/*   <!-- Single Tab Content End -->
                                                <!-- Single Tab Content Start --> */}
                                                <div className="tab-pane fade" id="orders" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3>Orders</h3>
                                                        <div className="myaccount-table table-responsive text-center">
                                                            <table className="table table-bordered">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th>Order</th>
                                                                        <th>Date</th>
                                                                        <th>Status</th>
                                                                        <th>Total</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Aug 22, 2018</td>
                                                                        <td>Pending</td>
                                                                        <td>$3000</td>
                                                                        <td><Link to={process.env.PUBLIC_URL +"/cart.html"} className="check-btn sqr-btn">View</Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>July 22, 2018</td>
                                                                        <td>Approved</td>
                                                                        <td>$200</td>
                                                                        <td><Link to={process.env.PUBLIC_URL +"/cart.html"} className="check-btn sqr-btn">View</Link></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>June 12, 2017</td>
                                                                        <td>On Hold</td>
                                                                        <td>$990</td>
                                                                        <td><Link to={process.env.PUBLIC_URL +"/cart.html"} className="check-btn sqr-btn">View</Link></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                
                                            {/*   <!-- Single Tab Content End -->
                                                <!-- Single Tab Content Start --> */}
                                                <div className="tab-pane fade" id="address-edit" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3>Billing Address</h3>
                                                        <address>
                                                            <p><strong>Alex Tuntuni</strong></p>
                                                            <p>1355 Market St, Suite 900 <br/>
                                                        San Francisco, CA 94103</p>
                                                            <p>Mobile: (123) 456-7890</p>
                                                        </address>
                                                        <Link to="#" className="check-btn sqr-btn "><i className="fa fa-edit"></i> Edit Address</Link>
                                                    </div>
                                                </div>
                                            {/*   <!-- Single Tab Content End -->
                                                <!-- Single Tab Content Start --> */}
                                                <div className="tab-pane fade" id="account-info" role="tabpanel">
                                                    <div className="myaccount-content">
                                                        <h3>Account Details</h3>
                                                        <div className="account-details-form">
                                                            <form action="#">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="single-input-item">
                                                                            <label htmlFor="first-name" className="required">First Name</label>
                                                                            <input type="text" id="first-name" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="single-input-item">
                                                                            <label htmlFor="last-name" className="required">Last Name</label>
                                                                            <input type="text" id="last-name" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="single-input-item">
                                                                    <label htmlFor="display-name" className="required">Display Name</label>
                                                                    <input type="text" id="display-name" />
                                                                </div>
                                                                <div className="single-input-item">
                                                                    <label htmlFor="email" className="required">Email Addres</label>
                                                                    <input type="email" id="email" />
                                                                </div>
                                                                <fieldset>
                                                                    <legend>Password change</legend>
                                                                    <div className="single-input-item">
                                                                        <label htmlFor="current-pwd" className="required">Current Password</label>
                                                                        <input type="password" id="current-pwd" />
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="single-input-item">
                                                                                <label htmlFor="new-pwd" className="required">New Password</label>
                                                                                <input type="password" id="new-pwd" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="single-input-item">
                                                                                <label htmlFor="confirm-pwd" className="required">Confirm Password</label>
                                                                                <input type="password" id="confirm-pwd" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                                <div className="single-input-item">
                                                                    <button className="check-btn sqr-btn ">Save Changes</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div> {/* <!-- Single Tab Content End --> */}
                                            </div>
                                        </div> {/* <!-- My Account Tab Content End --> */}
                                    </div>
                                </div> {/* <!-- My Account Page End --> */}
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
            </div>
}
}
export default withRouter(Myaccounts);

