import React, {Component} from 'react';
import axios from 'axios';
import AuthService from "../services/auth.service";
import { BrowserRouter, Route, Link, withRouter, Redirect } from 'react-router-dom';

class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            message: ""
          };
    }

    componentDidMount() {    
        const user = AuthService.getCurrentUser();
        if (user) this.setState({ redirect: "/my-account" });
      }


     handleLoginSubmit = e => {
        e.preventDefault();
         AuthService.login(this.userName, this.userPassword).then(
            () => {
             
              const { history } = this.props;
              this.props.history.push("/my-account");
              window.location.reload();
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
    }

    handleRegisterSubmit = e => {
        e.preventDefault();
        const registerData= { 
            full_name:this.userFullname,
            email:this.userEmail,
            mobile:this.userMobile
        }

        axios.post(process.env.REACT_APP_API_URL + 'api/method/windowworld.api.user_register', registerData,{
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
        'content-type': 'application/json'}
    })                
        .then( 
            res =>{ console.log(res) }
        )
        .catch(
            err =>{ console.log(err)}
        )
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
      return <div>
                <div className="breadcrumb-area bg-gray">
                            <div className="container">
                                <div className="breadcrumb-content text-center">
                                    <ul>
                                        <li>
                                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                                        </li>
                                        <li className="active">login-page</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="login-register-area pt-115 pb-120">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                                        <div className="login-register-wrapper">
                                            <div className="login-register-tab-list nav">
                                                <a className="active" data-toggle="tab" href="#lg1">
                                                    <h4> login </h4>
                                                </a>
                                                <a data-toggle="tab" href="#lg2">
                                                    <h4> register </h4>
                                                </a>
                                            </div>
                                            <div className="tab-content">
                                                <div id="lg1" className="tab-pane active">
                                                    <div className="login-form-container">
                                                        <div className="login-register-form">
                                                            <form action="#" onSubmit={this.handleLoginSubmit}>
                                                                <input type="text" name="user-name" placeholder="Username" 
                                                                    onChange={e => this.userName = e.target.value}/>
                                                                <input type="password" name="user-password" placeholder="Password"
                                                                    onChange={e => this.userPassword = e.target.value} />
                                                                <div className="button-box">
                                                                    <div className="login-toggle-btn">
                                                                        <input type="checkbox"/>
                                                                        <label>Remember me</label>
                                                                        <Link to="#">Forgot Password?</Link>
                                                                    </div>
                                                                    <button type="submit">Login</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="lg2" className="tab-pane">
                                                    <div className="login-form-container">
                                                        <div className="login-register-form">
                                                            <form action="#" onSubmit={this.handleRegisterSubmit}>
                                                                <input type="text" name="user-fullname" placeholder="Full Name"
                                                                onChange={e => this.userFullname = e.target.value}/>
                                                                <input name="user-email" placeholder="Email" type="text"
                                                                onChange={e => this.userEmail = e.target.value}/> 
                                                                <input type="text" name="user-mobile" placeholder="Mobile Number"
                                                                onChange={e => this.userMobile = e.target.value}/>
                                                                <div className="button-box">
                                                                    <button type="submit">Register</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        </div>
}
}
export default withRouter(Login);


