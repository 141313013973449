import React, { Component, lazy } from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import { Redirect } from 'react-router';

import {BrowserRouter as Router, Route, Switch, useParams, HashRouter } from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Rollerblinds from './pages/Rollerblinds';
import ProductDetails from './pages/ProductDetails';
import SampleDetails from './pages/Sampledetails';
import Daynight from './pages/Daynight';
import Venetian from './pages/Venetian';
import Romanblinds from './pages/Romanblinds';
import Verticalblinds from './pages/Verticalblinds';
import Panelglide from './pages/Panelglide';
import Plantation from './pages/Plantation';
import Measure from './pages/Measure';
import Install from './pages/Install';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Howtomeasure from './pages/Howtomeasure';
import Howtoinstall from './pages/Howtoinstall';
import Samples from './pages/Samples';
import Sampleslist from './pages/Sampleslist';
import Blog from './pages/Blog';
import Blogdetails from './pages/Blogdetails';
import Myaccounts from './pages/Myaccount';


ReactDOM.render(
       
  <React.StrictMode>
       
    <div>
      <Router basename={process.env.PUBLIC_URL}>
        <Header/>
        
       <Switch>
              <Route exact path="/how-to-measure/:slug2">
                     <Howtomeasure />
                 </Route> 
              <Route exact path="/how-to-install/:slug">
                     <Howtoinstall />
                 </Route> 
              <Route exact path="/checkout">
                      <Checkout />
                 </Route> 
              <Route exact path="/cart">
                      <Cart />
                 </Route> 
                 <Route exact path="/my-account">
                      <Myaccounts />
                 </Route> 
                 <Route exact path="/blog">
                      <Blog />
                 </Route> 
                  <Route exact path="/blog-details/:slug3">
                      <Blogdetails />
                 </Route> 
                <Route exact path="/">
                      <Home />
                 </Route> 
                 <Route exact path="/index">
                      <Home />
                 </Route> 
                 <Route exact path="/login-register">
                      <Login />
                 </Route> 
                 <Route exact path="/roller-blinds">
                        <Rollerblinds cat="Roller Blinds" />
                 </Route>
                
                 <Route exact path="/day-night-roller-blinds" >
                        <Daynight />
                 </Route> 
                
                 <Route path="/venetian-blinds" >
                        <Venetian />
                 </Route> 
                
                 <Route exact path="/roman-blinds">
                        <Romanblinds />
                 </Route> 
                 <Route exact path="/:catg/:route/:id">
                        <ProductDetails />
                 </Route>
                 <Route exact path="/vertical-blinds" >
                        <Verticalblinds />
                 </Route>
                
                 <Route exact path="/panel-glide-blinds" >
                        <Panelglide />
                </Route>
               
                 <Route path="/plantation-shutters" >
                        <Plantation />
                 </Route> 
  
                <Route path="/how-to-measure">
                         <Measure />
                </Route>
               
                <Route path="/how-to-install">
                         <Install />
                </Route>
                <Route path="/contact">
                          <Contact />
                </Route>
                  
                <Route exact path="/samples/roller-blinds-order-free-samples">
                     <Sampleslist groups="Roller Blinds" />
                 </Route> 
                 <Route exact path="/samples/day-night-roller-blinds">
                     <Sampleslist groups="Day And Night Roller Blinds" />
                 </Route> 
                 <Route exact path="/samples/venetian-blinds">
                     <Sampleslist groups="Venetian Blinds" />
                 </Route> 
                 <Route exact path="/samples/roman-blinds">
                     <Sampleslist groups="Roman Blinds" />
                 </Route> 
                 <Route exact path="/samples/vertical-blinds">
                     <Sampleslist groups="Vertical Blinds" />
                 </Route> 
                 <Route exact path="/samples/panel-glide-blinds">
                     <Sampleslist groups="Panel Glide Blinds"/>
                 </Route> 
                 <Route exact path="/samples/plantation-shutters">
                     <Sampleslist groups="Shutters"/>
                 </Route> 
                 <Route exact path="/samples/:route/:id">
                        <SampleDetails cat="Panel Glide Blinds" />
                 </Route>
            </Switch>
           
        <Footer/>
        <Helmet>
              <script src="assets/js/main.js" type="text/javascript" />
       </Helmet>
      </Router>
      </div>
     
      </React.StrictMode>,
  document.getElementById('root')
);

  
