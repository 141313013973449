import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
let itemList=[];
let element ={};
class Blindoptions extends React.Component {
    
    constructor(props){
       
        super(props);
        this.state = {
            on: [],
            values: [],
             options : []
        }
        
        this.handleChild = this.handleChild.bind(this)
        
    }
    imgClick= e => {
        
        
        
       //alert(e.target.dataset.label)
       //alert(e.target.dataset.option)
        //alert(document.getElementsByClassName("selected " + e.target.dataset.id).length)
      // let node = document.getElementById(e.target.dataset.id)
       // alert(node.length)
        //var para = document.createElement("P");                  
        //var t = document.createTextNode("<strong>" + e.target.dataset.label + "</strong> : " + e.target.dataset.option);      // Create a text node
        //para.appendChild(t);    
        //document.getElementById("options-selected").innerHTML = "<strong>" + e.target.dataset.label + "</strong> : " + e.target.dataset.option;
        if(document.getElementsByClassName("selected " + e.target.dataset.id).length > 0){
           // document.getElementById("new-price").innerHTML = "$65";
            element[e.target.dataset.id]=e.target.dataset.value; 
            this.setState({options:element});
      
            //options.push([e.target.dataset.id]= e.target.dataset.option);
            //let eli = document.getElementsByClassName("selected " + e.target.dataset.id);    
           // ReactDOM.render("<strong>" + e.target.dataset.label + " 123</strong> : " + e.target.dataset.option, eli );
            document.getElementById(e.target.dataset.id).remove();
            document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='"+ e.target.dataset.id + "' class=' selected " + e.target.dataset.id + "'><strong>" + e.target.dataset.label + "</strong> : " + e.target.dataset.option + "</div>");                                 
            this.setState({total: "$65"});
            
           // console.log(this.state.OPT00064)

        }
        else
        {   document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='"+ e.target.dataset.id + "' class=' selected " + e.target.dataset.id + "'><strong>" + e.target.dataset.label + "</strong> : " + e.target.dataset.option + "</div>");                                 
            //document.getElementById("new-price").innerHTML = "65";
            element[e.target.dataset.id]=e.target.dataset.value; 
        }
            
            const width = document.getElementById('width').value
            const drop = document.getElementById('drop').value
          
            const ret = {"options":element,"width":width,"drop":drop}
            console.log(ret)
            axios.post(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.get_price'), ret, {
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                console.log(response.data['message'])
                if(response.data['message']['total'] > 0){
                    // document.getElementById("new-price").innerHTML = "$" + response.data['message']['total'];
                     this.props.updateState(response.data['message']['total'])
                }
                    
            })
           
       //$(".opt" + $(param).attr('data-id')).html("<strong>" + $(param).attr('data-label') + "</strong> : " + $(param).attr('data-option'));

       //console.log(document.getElementById('options-selected'))
     // let node = document.getElementById('options-selected');
        //console.log($(param).closest("div").attr('id'));
      /*  console.log($(param).attr('data-label'));
        console.log($(param).attr('data-option'));
        
        
          if($(".opt" + $(param).attr('data-id')).length == 0){
                  $("#side-price .price-wrapper").append( "<div class='opt" + $(param).attr('data-id') + "'><strong>" + $(param).attr('data-label') + "</strong> : " + $(param).attr('data-option') + "</div>" );
          }
          else{
               $(".opt" + $(param).attr('data-id')).html("<strong>" + $(param).attr('data-label') + "</strong> : " + $(param).attr('data-option'));
          }*/
      }
    async componentDidMount(){
        
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Product Options/') + this.props.OptionName, {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({on:response.data['data']})
           // this.setState({values:response.data['data']['values']})
           // console.log(response.data['data'])
            itemList =[]
            this.state.on['values'].forEach(async (item,index)=>{
               
                //itemList.push(<div>{item.option_value_name} - </div>)
                var cClass = (item.parent_option) ? "child-class" : "parent-class";
                var pID = (item.parent_option_value) ? item.parent_option_value : "";

                    if (item.parent_option)
                    {
                        itemList.push(<div className={cClass + " " + pID + " radio-selector mb-3 " + item.name + " " + item.parent_option} > 
                               <input id={item.name} type="radio"  name={item.parent} value={item.option_value_name} data-parent={this.state.on['values'][index].parent}   />
                                <label data-value={item.name} onClick={this.imgClick}  data-id={this.state.on['values'][index].parent} data-label={this.state.on.app_label} data-option={item.option_value_name} className="radio-label" htmlFor={item.name} style={{ backgroundImage:`url(${process.env.REACT_APP_API_URL + encodeURI(item.image)})`}}><span>{item.option_value_name}</span></label>            
                            </div>)
                    }
                    else{
                        itemList.push(<div className={cClass + " " + pID + "12 radio-selector mb-3 " + item.name} onClick={() => this.handleChild(item.name)}  > 
                               <input id={item.name} type="radio" data-parent={this.state.on['values'][index].parent} name={item.parent} value={item.option_value_name}  />
                                <label data-value={item.name} onClick={this.imgClick}  data-id={this.state.on['values'][index].parent}  data-label={this.state.on.app_label} data-option={item.option_value_name}  className="radio-label" htmlFor={item.name} style={{ backgroundImage:`url(${process.env.REACT_APP_API_URL + encodeURI(item.image)})`}}><span>{item.option_value_name}</span></label>            
                            </div>)

                    }
            })
            this.setState({values:itemList})
            
        })
        
       
    }
 
    handleChild(pid) {
       
       // document.getElementsByClassName(".child-class."+pid)[0].style.display="block";
       let mParent = document.getElementById(pid).dataset.parent;
      // alert(mParent)
       let allChilds = document.getElementsByClassName('child-class ' + mParent);
       
       for(var i = 0; i < allChilds.length; i++) {

            allChilds[i].style.display = 'none';
       }
       let childs = document.getElementsByClassName(pid);
       
       for(var i = 0; i < childs.length; i++) {
            childs[i].style.display = 'block';
        }
        


    }
    render() {
        
       // console.log("Option Component" + this.props.OptionName) 
       // console.log(this.state.values)
            return <>
                    <div className="col-lg-12 col-md-12 pl-0"> 
                        <h5>{this.state.on.app_label} </h5> 
                    </div>
                    <div className="col-lg-12 col-md-12"> 
                        <div className={"row "}> 
                            {this.state.values}
                        </div>
                    </div>
                </> 
    }
}
export default Blindoptions;
