
import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

class Measure extends React.Component {
        constructor(){
            super();
            this.state = {
                pd : [],
                metaTitle:[],
                metaDesc:[],
                metaOthers:[]
            }
        }
        componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/resource/Web%20Page?filters=[[%22page_catagory%22,%22=%22,%22how%20to%20measure%22],[%22published%22,%22=%22,%221%22]]&fields=[%22name%22,%22title%22,%22meta_image%22]', {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({pd:response.data['data']})
            console.log(response.data['data'])
        })
        // Dynamic Meta Tags
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Route Meta/roller-blinds'), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            let othrMeta= []
            response.data['data']['meta_tags'].forEach(async (item,index)=>{
                    if(item['key']==='title'){
                        this.setState({metaTitle:item['value']})
                    }
                    else if(item['key']==='description'){
                        this.setState({metaDesc:item['value']})
                    }
                    else{
                        othrMeta.push(<meta property={item['key']} content={item['value']} /> )
                    }                    
            })
            this.setState({metaOthers:othrMeta})
        })

        //Dynamic Meta

        }
        
        render() {
          return <div>
           <Helmet>
                <title>{this.state.metaTitle}</title>
                <meta 
                    name="description" 
                    content={this.state.metaDesc}
                />
                {this.state.metaOthers}
        </Helmet>   
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li className="active">How to measure </li>
                    </ul>
                </div>
            </div>
        </div>
        <div>
        
        <div className="product-details-area pt-120 pb-115">
            <div className="row">
            {this.state.pd.map((itm,k)=>{
            return(
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="single-product-wrap mb-35">
                    <div className="product-img product-img-zoom mb-15">
                 
                <div className="banner-text banner-caption"><span>{itm.title}</span></div>
                <Link to={'how-to-install/'+itm.name}>
                <img src={'http://api.windowworldonline.com.au/'+itm.meta_image}  alt="" width="100%" height=""/>
                <div className="banner-text banner-caption-2"><span>How to Install</span></div>
                </Link>
                </div>
                                    
                                    </div>
                                </div>)
             })} 
          </div>
     
            </div>
        </div>
</div>

}
    }
    export default Measure;