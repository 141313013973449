
import React, {Component} from 'react';
import HomeSection from '../Component/HomeSection';
import {Link} from 'react-router-dom';
class Footer extends React.Component {
    render() {

        const imgstyle = {
            width: "285px",
            height: "auto",
          
          };

      return <div>
   
    <footer className="footer-area bg-gray-4 pt-95">
            <div className="footer-top border-bottom-4 pb-55">
                <div className="container">
                    <div className="row">
                       <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="footer-widget ml-70 mb-40">
                                <h3 className="footer-title">Our Products</h3>
                                <div className="footer-info-list">
                                    <ul>
                                        <li><Link to="#">Roller Blinds</Link></li>
                                        <li><Link to="#">Day & Night Roller Blinds</Link></li>
                                        <li><Link to="#">Venetian Blinds</Link></li>
                                        <li><Link to="#">Vertical Blinds</Link></li>
                                        <li><Link to="#">Roman Blinds</Link></li>
                                        <li><Link to="#">Shutters</Link></li>
                                        <li><Link to="#">Panel Glide Blinds</Link></li>
                                        <li><Link to="#">Curtains</Link></li>
                                        <li><Link to="#">Roller Shutters</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="footer-widget ml-70 mb-40">
                                <h3 className="footer-title">useful links</h3>
                                <div className="footer-info-list">
                                    <ul>
                                        <li><Link to="/my-account.html">My Account</Link></li>
                                        <li><Link to="/wishlist.html">My Wishlist</Link></li>
                                        <li><Link to="#">Term & Conditions</Link></li>
                                        <li><Link to="#">Privacy Policy</Link></li>
                                        <li><Link to="#">Track Order</Link></li>
                                        <li><Link to="/shop.html">Shop</Link></li>
                                        <li><Link to="/about-us.html">About Us</Link></li>
                                        <li><Link to="#">Returns/Exchange</Link></li>
                                        <li><Link to="#">FAQs</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="footer-widget mb-40 ">
                <HomeSection section="Footer Blocks"/>
                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom pt-30 pb-30 ">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6 col-md-6">
                            <div className="payment-img payment-img-right">
                                 <Link to="#"><img src="assets/images/icon-img/payment.png" width="1" height="1" style={imgstyle}  alt=""/></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="copyright copyright-center">
                                <p>© 2021 Window World Online. All rights reserved 
								{/* <!-- */}<Link to="#"> Built by prismaticsoft</Link>{/* --> */}
								</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            
        </footer>

        </div>
        
    }
}
export default Footer;