
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class Checkout extends React.Component {

    constructor(props){
       
        super(props);
        this.state = {
            cartItems: [],
            cartTotal: [],
            cartGrandTotal: [],
        }
        
        
        
    }

    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.get_cart_quote'),{
                headers : {'Authorization' : USR_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cartItems:response.data['message']['items']})
                this.setState({cartTotal:response.data['message']['net_total']})
                this.setState({cartGrandTotal:response.data['message']['grand_total']})
                console.log(response.data['message']['items'])
            })
        }
    }


    render() {
      return <div>

    <div className="main-wrapper">
       
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li className="active">Checkout-page </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="checkout-main-area pt-120 pb-120">
            <div className="container">
                <div className="customer-zone mb-20">
                    <p className="cart-page-title">Returning customer? <Link to="/login-register" className="checkout-click1">Click here to login</Link></p>
                    <div className="checkout-login-info">
                        <p>If you have shopped with us before, please enter your details in the boxes below. If you are a new customer, please proceed to the Billing & Shipping section.</p>
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="sin-checkout-login">
                                        <label>Username or email address <span>*</span></label>
                                        <input type="text" name="user-name"/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="sin-checkout-login">
                                        <label>Passwords <span>*</span></label>
                                        <input type="password" name="user-password"/>
                                    </div>
                                </div>
                            </div>
                            <div className="button-remember-wrap">
                                <button className="button" type="submit">Login</button>
                                <div className="checkout-login-toggle-btn">
                                    <input type="checkbox"/>
                                    <label>Remember me</label>
                                </div>
                            </div>
                            <div className="lost-password">
                            <Link to="#">Lost your password?</Link>
                            </div>
                        </form>
                        <div className="checkout-login-social">
                            <span>Login with:</span>
                            <ul>
                                <li> <Link to="#">Facebook</Link></li>
                                <li> <Link to="#">Twitter</Link></li>
                                <li> <Link to="#">Google</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="checkout-wrap pt-30">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="billing-info-wrap mr-50">
                                <h3>Billing Details</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="billing-info mb-20">
                                            <label>First Name <abbr className="required" title="required">*</abbr></label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="billing-info mb-20">
                                            <label>Last Name <abbr className="required" title="required">*</abbr></label>
                                            <input type="text"/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="billing-select mb-20">
                                            <label>Country <abbr className="required" title="required">*</abbr></label>
                                            <select>
                                                <option>Australia</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="billing-info mb-20">
                                            <label>Street Address <abbr className="required" title="required">*</abbr></label>
                                            <input className="billing-address" placeholder="House number and street name" type="text"/>
                                            <input placeholder="Apartment, suite, unit etc." type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="billing-info mb-20">
                                            <label>Town / City <abbr className="required" title="required">*</abbr></label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                  
                                  
                                    <div className="col-lg-12 col-md-12">
                                        <div className="billing-info mb-20">
                                            <label>Phone <abbr className="required" title="required">*</abbr></label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="billing-info mb-20">
                                            <label>Email Address <abbr className="required" title="required">*</abbr></label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="checkout-account-toggle open-toggle2 mb-30">
                                    <label>Password</label>
                                    <input placeholder="Password" type="password"/>
                                </div>
                                <div className="checkout-account-toggle open-toggle2 mb-30">
                                    <label>Confirm Password</label>
                                    <input placeholder="Password" type="password"/>
                                </div>
                                <div className="different-address open-toggle mt-30">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="billing-info mb-20">
                                                <label>First Name</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="billing-info mb-20">
                                                <label>Last Name</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="billing-info mb-20">
                                                <label>Company Name</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="billing-select mb-20">
                                                <label>Country</label>
                                                <select>
                                                    <option>Select a country</option>
                                                    <option>Azerbaijan</option>
                                                    <option>Bahamas</option>
                                                    <option>Bahrain</option>
                                                    <option>Bangladesh</option>
                                                    <option>Barbados</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="billing-info mb-20">
                                                <label>Street Address</label>
                                                <input className="billing-address" placeholder="House number and street name" type="text"/>
                                                <input placeholder="Apartment, suite, unit etc." type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="billing-info mb-20">
                                                <label>Town / City</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="billing-info mb-20">
                                                <label>State / County</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="billing-info mb-20">
                                                <label>Postcode / ZIP</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="billing-info mb-20">
                                                <label>Phone</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="billing-info mb-20">
                                                <label>Email Address</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="additional-info-wrap">
                                    <label>Order notes</label>
                                    <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="your-order-area">
                                <h3>Your order</h3>
                                <div className="your-order-wrap gray-bg-4">
                                    <div className="your-order-info-wrap">
                                        <div className="your-order-info">
                                            <ul>
                                                <li>Product <span>Total</span></li>
                                            </ul>
                                        </div>
                                        <div className="your-order-middle">
                                            <ul>
                                            {this.state.cartItems.map((itm,j)=>{
                                             return(
                                                <li key={j}>{itm.item_name} X 1 <span>${itm.rate} </span></li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="your-order-info order-subtotal">
                                            <ul>
                                                <li>Subtotal <span>${this.state.cartTotal} </span></li>
                                            </ul>
                                        </div>
                                        <div className="your-order-info order-shipping">
                                            <ul>
                                                <li>Shipping <p>$30 </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="your-order-info order-total">
                                            <ul>
                                                <li>Total <span>${this.state.cartTotal} </span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="payment-method">
                                        <div className="pay-top sin-payment sin-payment-3">
                                            <input id="payment-method-4" className="input-radio" checked="checked" type="radio" value="cheque" name="payment_method"/>
                                            <label for="payment-method-4">Credit/Debit Card <img alt="" src="assets/images/icon-img/payment.png"/></label>
                                            <div className="payment-box payment_method_bacs">
                                                <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Place-order">
                                <Link to="#">Place Order</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
  </div>
}
}
export default Checkout;

