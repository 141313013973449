import React, {Component} from 'react';
import { Link } from 'react-router-dom';
class Blogsearch extends React.Component {
    render() {
      return <div>
 <div className="sidebar-widget mb-40">
                            <h4 className="sidebar-widget-title">Search </h4>
                            <div className="sidebar-search">
                                <form className="sidebar-search-form" action="#">
                                    <input type="text" placeholder="Search Post" />
                                    <button>
                                        <i className="icon-magnifier"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
      </div>
      }
    }
    export default Blogsearch;