import React, {Component} from 'react';
import axios from 'axios';
import Rightcatagories from '../Component/Rightcatagories';
import {Helmet} from "react-helmet";

import { Link } from 'react-router-dom';

class Daynight extends React.Component {
    constructor(){
        super();
        this.state = {
            day : [],
            metaTitle:[],
            metaDesc:[],
            metaOthers:[]
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Products?fields=["product_name","base_rate","product_image","name","route"]&filters=[["product_group","=","Day And Night Roller Blinds"],["enabled","=","1"]]'), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({day:response.data['data']})
            console.log(response.data['data'])
        })

        // Dynamic Meta Tags
        axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Route Meta/roller-blinds'), {
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            let othrMeta= []
            response.data['data']['meta_tags'].forEach(async (item,index)=>{
                    if(item['key']==='title'){
                        this.setState({metaTitle:item['value']})
                    }
                    else if(item['key']==='description'){
                        this.setState({metaDesc:item['value']})
                    }
                    else{
                        othrMeta.push(<meta property={item['key']} content={item['value']} /> )
                    }                    
            })
            this.setState({metaOthers:othrMeta})
        })

        //Dynamic Meta
    }
    render() {
      return <div>
            <Helmet>
                <title>{this.state.metaTitle}</title>
                <meta 
                    name="description" 
                    content={this.state.metaDesc}
                />
                {this.state.metaOthers}
            </Helmet>
        <div className="breadcrumb-area bg-gray">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <ul>
                        <li>
                        <Link to={process.env.PUBLIC_URL +"/index.html"}>Home</Link>
                        </li>
                        <li className="active">Day & Nights </li>
                    </ul>
                </div>
            </div>
        </div>
	<div className="shop-area pt-50 pb-50">
            <div className="container">
				<h3>DAY & NIGHT</h3>
                <div className="row">
                    <div className="col-lg-9">
                        
                        
                        <div className="shop-bottom-area">
                            <div className="tab-content jump">
                                <div id="shop-1" className="tab-pane active">
                                    <div className="row">
                                    {this.state.day.map((itm,k)=>{
                                    return(
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="single-product-wrap mb-35">
                                                <div className="product-img product-img-zoom mb-15">
                                                <Link to={`/day-night-roller-blinds/${ itm.route }/${ itm.name }`} >
                                                        <img src={'http://api.windowworldonline.com.au/'+itm.product_image} alt=""/>
                                                </Link> 
                                                    
                                                </div>
                                                <div className="product-content-wrap-2 text-center">
                                                    
                                                    <h3><Link to={`/day-night-roller-blinds/${ itm.route }/${ itm.name }`} >{ itm.product_name }</Link></h3>
                                                    <div className="product-price-2">
                                                        <span>${itm.base_rate}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content-wrap-2 product-content-position text-center">
                                                   
                                                    <h3><Link to={`/day-night-roller-blinds/${ itm.route }/${ itm.name }`} >{ itm.product_name }</Link></h3>
                                                    <div className="product-price-2">
                                                        <span>${itm.base_rate}</span>
                                                    </div>
                                                    <div className="pro-add-to-cart">
                                                        <button title="Shop Now">SHOP NOW</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                         })} 
                                    </div>
                                   
                                </div>
                                
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3">
                      <Rightcatagories/>
                    </div>
                </div>
            </div>
        </div>
      
        
    
    </div>

}
}
export default Daynight;