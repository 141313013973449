import React, {Component} from 'react';
import { Link } from 'react-router-dom';
class Blogcatagories extends React.Component {
    render() {
      return <div>
<div className="sidebar-widget shop-sidebar-border mb-35 pt-40">
                            <h4 className="sidebar-widget-title">Categories </h4>
                            <div className="shop-catigory">
                                <ul>
                                    <li> <Link to={process.env.PUBLIC_URL +"/shop.html"}>T-Shirt</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/shop.html"}>Shoes</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/shop.html"}>Clothing </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/shop.html"}>Women </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/shop.html"}>Baby Boy </Link></li>
                                    <li><Link to={process.env.PUBLIC_URL +"/shop.html"}>Accessories </Link></li>
                                </ul>
                            </div>
                        </div>
      </div>
      }
    }
    export default Blogcatagories;